import { BadgeList as BadgeListComponent } from '@wix/members-badge-lib';
import type { FC } from 'react';
import React from 'react';

import type { BadgeSettings } from '../../../types';
import styles from './BadgeList.scss';
import { useBadgeListKey } from './useBadgeListKey';

export const BadgeList: FC<BadgeSettings> = (props) => {
  const { key } = useBadgeListKey();

  return (
    <BadgeListComponent
      {...props}
      key={key}
      className={styles.badgeList}
      background={true}
    />
  );
};
