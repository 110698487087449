import { useStyles } from '@wix/tpa-settings/react';
import { useRef, useState } from 'react';

import stylesParams from '../../../components/ProfileCard/stylesParams';
import type { BadgeLayout, BadgeSize } from '../../../types';

// Workaround to rerender BadgeList so it works correctly with css vars in editor
export const useBadgeListKey = () => {
  const [rerenderKey, setRerenderKey] = useState(0);

  const styles = useStyles();
  const badgeLayout: BadgeLayout = styles.get(stylesParams.badgeLayout);
  const badgeSize: BadgeSize = styles.get(stylesParams.badgeSize);
  const badgeFont = styles.get(stylesParams.badgeFont).family ?? '';
  const prevBadgeLayout = useRef(badgeLayout);
  const prevBadgeSize = useRef(badgeSize);
  const prevBadgeFont = useRef(badgeFont);

  if (
    prevBadgeLayout.current !== badgeLayout ||
    prevBadgeSize.current !== badgeSize ||
    prevBadgeFont.current !== badgeFont
  ) {
    prevBadgeLayout.current = badgeLayout;
    prevBadgeSize.current = badgeSize;
    prevBadgeFont.current = badgeFont;
    setRerenderKey((prev) => prev + 1);
  }

  return { key: `BadgeList-${rerenderKey}` };
};
